import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://3589586f4415f6b6db6f6980cedf16c8@o4507102276878336.ingest.de.sentry.io/4507106645966928",
  debug: false,
  environment: "preview",
  release: "de-publieke-tribune:2025-01-14 - main:preview",
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});